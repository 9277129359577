<template>
  <base-card-details :title="nome || 'Não definido'">
    <template v-slot:header>
      <v-list-item-avatar dark color="grey" size="34">
        <span class="white--text subtitle-1 mt-1">{{nome | avatarName}}</span>
      </v-list-item-avatar>
    </template>
    <template v-slot:body>
       <v-row no-gutters>
        <v-col cols="12" class="mb-4">
          <div>
            <h3 class="mb-1 subtitle-2">Email</h3>
            <span>{{email}}</span>
          </div>
        </v-col>
        <div class="d-flex mb-4 col-12">
          <div>
            <h3 class="mb-1 subtitle-2">Telefone</h3>
            <span>{{telefone}}</span>
          </div>
          <div class="ml-6">
            <h3 class="mb-1 subtitle-2">Tipo Telefone</h3>
            <span>{{tipoTelefone}}</span>
          </div>
        </div>
      </v-row>
    </template>
     <template v-slot:footer>
      <div class="d-flex justify-space-between col-12">
        <div v-if="quantidade === 1" class="text-caption">{{quantidade}} Simulação vinculada</div>
        <div v-else class="text-caption">{{quantidade}} Simulações vinculadas</div>
      </div>
    </template>
  </base-card-details>
</template>

<script>
import BaseCardDetails from '@/components/BaseCardDetails.vue';
import moment from 'moment';

export default {
  components: {
    BaseCardDetails,
  },
  props: {
    nome: {
      type: String,
    },
    avatar: {
      type: String,
    },
    data: {
      type: String,
    },
    telefone: {
      type: String,
      default: '--',
    },
    tipoTelefone: {
      type: String,
      default: '--',
    },
    quantidade: {
      type: Number,
      default: 0,
    },
    email: {
      type: String,
      default: '--',
    },
    permitidoTransmissao: {
      type: Boolean,
    },
  },
  computed: {
    descricaoPermitidoTransmissao() {
      if (this.permitidoTransmissao) {
        return 'No prazo para transmissão';
      }
      if (this.permitidoTransmissao === false) {
        return 'Fora do prazo de transmissão';
      }
      return '';
    },
    showClock() {
      return this.permitidoTransmissao === true || this.permitidoTransmissao === false;
    },
  },
  methods: {
    formataNascimento(data) {
      return moment(data).format('DD/MM/YYYY');
    },
    formataData(data) {
      return moment(data).format('DD/MM/YYYY hh:mm:ss');
    },
  },
};
</script>

<style>
</style>
