var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "the-toolbar",
        { attrs: { title: "Simulações" } },
        [
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.getSimulacoes } },
            [_c("v-icon", [_vm._v("mdi-reload")])],
            1
          ),
          _c(
            "v-btn",
            {
              attrs: { icon: "" },
              on: {
                click: function ($event) {
                  _vm.dialogFiltro = true
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-magnify")])],
            1
          ),
          _c(
            "v-btn",
            { attrs: { icon: "" }, on: { click: _vm.criarSimulacao } },
            [_c("v-icon", [_vm._v("mdi-plus")])],
            1
          ),
        ],
        1
      ),
      _vm.loading
        ? _c(
            "div",
            { staticClass: "d-flex justify-center mt-10 pa-10" },
            [
              _c("v-progress-circular", {
                attrs: { color: "primary", indeterminate: "" },
              }),
            ],
            1
          )
        : _c("v-container", { staticClass: "pa-5 pt-2" }, [
            _vm.list.length === 0
              ? _c("div", { staticClass: "mt-8" }, [
                  _c(
                    "div",
                    { staticClass: "text-center text-h6 primary--text" },
                    [_vm._v("Não há simulações.")]
                  ),
                  _c("div", { staticClass: "text-center mt-2" }, [
                    _vm._v("Aqui aparecem as simulações que você salvou"),
                  ]),
                ])
              : _c(
                  "div",
                  [
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", [
                          _c(
                            "div",
                            {
                              staticClass:
                                "text-center ma-2 subtitle-2 grey--text",
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.list.length) +
                                  " registro(s) encontrados(s)"
                              ),
                            ]
                          ),
                          _vm.filtroAtivo
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "text-center ma-2 subtitle-2 primary--text cursor-pointer mt-n2",
                                  on: { click: _vm.limparFiltros },
                                },
                                [_vm._v(" limpar filtros ")]
                              )
                            : _vm._e(),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-row",
                      _vm._l(_vm.list, function (item, index) {
                        return _c(
                          "v-col",
                          {
                            key: `item-${index}`,
                            attrs: { cols: "12", sm: "6" },
                            on: {
                              click: function ($event) {
                                return _vm.editar(item)
                              },
                            },
                          },
                          [
                            _c("base-card-details-simulacao", {
                              staticClass: "cursor-pointer",
                              attrs: {
                                nome: item.nome,
                                email: item.email,
                                telefone: item.telefone,
                                tipoTelefone: item.tipoTelefone,
                                quantidade: item.simulacoes.length,
                              },
                            }),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
          ]),
      _c(
        "v-btn",
        {
          staticClass: "mt-10",
          staticStyle: { bottom: "30px" },
          attrs: {
            color: "primary",
            fixed: "",
            dark: "",
            right: "",
            bottom: "",
            fab: "",
          },
          on: { click: _vm.criarSimulacao },
        },
        [_c("v-icon", [_vm._v("mdi-plus")])],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.dialogFiltro,
            callback: function ($$v) {
              _vm.dialogFiltro = $$v
            },
            expression: "dialogFiltro",
          },
        },
        [
          _c(
            "v-card",
            [
              _c("v-card-title", [_vm._v(" Filtrar ")]),
              _c(
                "v-card-text",
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("base-text-field", {
                            attrs: {
                              id: "nome",
                              name: "nome",
                              label: "Buscar por nome",
                              clearable: "",
                              outlined: "",
                            },
                            model: {
                              value: _vm.filtros.nome,
                              callback: function ($$v) {
                                _vm.$set(_vm.filtros, "nome", $$v)
                              },
                              expression: "filtros.nome",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-n4", attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'",
                              },
                            ],
                            attrs: {
                              autoBlur: 10,
                              rules: "date",
                              inputmode: "numeric",
                              type: "text",
                              id: "dataInicio",
                              name: "dataInicio",
                              label: "Data Início",
                              clearable: "",
                              placeholder: "dd/mm/aaaa",
                              outlined: "",
                            },
                            model: {
                              value: _vm.filtros.dataInicio,
                              callback: function ($$v) {
                                _vm.$set(_vm.filtros, "dataInicio", $$v)
                              },
                              expression: "filtros.dataInicio",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-n4", attrs: { cols: "6" } },
                        [
                          _c("base-text-field", {
                            directives: [
                              {
                                name: "mask",
                                rawName: "v-mask",
                                value: "##/##/####",
                                expression: "'##/##/####'",
                              },
                            ],
                            attrs: {
                              autoBlur: 10,
                              rules: "date",
                              inputmode: "numeric",
                              type: "text",
                              id: "dataFim",
                              name: "dataFim",
                              label: "Data Fim",
                              clearable: "",
                              placeholder: "dd/mm/aaaa",
                              outlined: "",
                            },
                            model: {
                              value: _vm.filtros.dataFim,
                              callback: function ($$v) {
                                _vm.$set(_vm.filtros, "dataFim", $$v)
                              },
                              expression: "filtros.dataFim",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-n4", attrs: { cols: "12" } },
                        [
                          _c("base-autocomplete-field", {
                            attrs: {
                              items: _vm.orderBy,
                              rules: "required",
                              id: "orderBy",
                              name: "orderBy",
                              label: "Ordernar Por",
                              "item-text": "titulo",
                              "item-value": "valor",
                              outlined: "",
                            },
                            model: {
                              value: _vm.filtros.orderBy,
                              callback: function ($$v) {
                                _vm.$set(_vm.filtros, "orderBy", $$v)
                              },
                              expression: "filtros.orderBy",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "v-card-actions",
                { staticClass: "mt-n5" },
                [
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-10",
                      attrs: {
                        color: "primary darken-1",
                        text: "",
                        outlined: "",
                      },
                      on: { click: _vm.limparFiltros },
                    },
                    [_vm._v(" limpar ")]
                  ),
                  _c(
                    "v-btn",
                    {
                      staticClass: "px-10 ml-2",
                      attrs: { color: "primary darken-1" },
                      on: { click: _vm.filtrar },
                    },
                    [_vm._v(" filtrar ")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }