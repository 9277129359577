<template>
    <div>
        <the-toolbar title="Simulações">
          <v-btn icon @click="getSimulacoes">
            <v-icon>mdi-reload</v-icon>
          </v-btn>
          <v-btn icon @click="dialogFiltro = true">
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
          <v-btn icon @click="criarSimulacao">
            <v-icon>mdi-plus</v-icon>
          </v-btn>
        </the-toolbar>
        <div v-if="loading" class="d-flex justify-center mt-10 pa-10">
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </div>
        <v-container class="pa-5 pt-2" v-else>
          <div class="mt-8" v-if="list.length === 0">
            <div class="text-center text-h6 primary--text">Não há simulações.</div>
            <div class="text-center mt-2">Aqui aparecem as simulações que você salvou</div>
          </div>
          <div v-else>
            <v-row no-gutters>
              <v-col>
                <div class="text-center ma-2 subtitle-2 grey--text">{{ list.length }} registro(s) encontrados(s)</div>
                <div class="text-center ma-2 subtitle-2 primary--text cursor-pointer mt-n2" v-if="filtroAtivo" @click="limparFiltros">
                  limpar filtros
                </div>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="(item, index) in list"
                :key="`item-${index}`"
                cols="12"
                sm="6"
                @click="editar(item)">
                <base-card-details-simulacao
                  class="cursor-pointer"
                  :nome="item.nome"
                  :email="item.email"
                  :telefone="item.telefone"
                  :tipoTelefone="item.tipoTelefone"
                  :quantidade="item.simulacoes.length"
                />
              </v-col>
            </v-row>
          </div>
        </v-container>
        <v-btn color="primary" fixed dark right bottom fab class="mt-10" style="bottom: 30px" @click="criarSimulacao">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
        <v-dialog
        v-model="dialogFiltro"
        max-width="400px"
      >
        <v-card>
          <v-card-title>
            Filtrar
          </v-card-title>
          <v-card-text>
          <v-row>
            <v-col cols="12">
              <base-text-field
                id="nome"
                name="nome"
                v-model="filtros.nome"
                label="Buscar por nome"
                clearable
                outlined
              ></base-text-field>
            </v-col>
            <v-col cols="6" class="mt-n4">
              <base-text-field
                :autoBlur="10"
                :rules="'date'"
                inputmode="numeric"
                type="text"
                id="dataInicio"
                name="dataInicio"
                label="Data Início"
                clearable
                v-mask="'##/##/####'"
                placeholder="dd/mm/aaaa"
                outlined
                v-model="filtros.dataInicio"
              ></base-text-field>
            </v-col>
            <v-col cols="6" class="mt-n4">
              <base-text-field
                :autoBlur="10"
                :rules="'date'"
                inputmode="numeric"
                type="text"
                id="dataFim"
                name="dataFim"
                label="Data Fim"
                clearable
                v-mask="'##/##/####'"
                placeholder="dd/mm/aaaa"
                outlined
                v-model="filtros.dataFim"
              ></base-text-field>
            </v-col>
            <v-col cols="12" class="mt-n4">
              <base-autocomplete-field
                :items="orderBy"
                rules="required"
                id="orderBy"
                name="orderBy"
                label="Ordernar Por"
                v-model="filtros.orderBy"
                item-text="titulo"
                item-value="valor"
                outlined
              >
              </base-autocomplete-field>
            </v-col>
          </v-row>
          </v-card-text>
          <v-card-actions class="mt-n5">
            <v-spacer></v-spacer>
            <v-btn
            class="px-10"
              color="primary darken-1"
              text
              @click="limparFiltros"
              outlined
            >
              limpar
            </v-btn>
            <v-btn
              class="px-10 ml-2"
              color="primary darken-1"
              @click="filtrar"
            >
              filtrar
            </v-btn>
        </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
</template>

<script>
import simulacaoService from '@/services/simulacaoService';
import { mapActions, mapState } from 'vuex';
import TheToolbar from '@/components/TheToolbar.vue';
import BaseCardDetailsSimulacao from '@/components/simulacoes/BaseCardDetailsSimulacao.vue';
import BaseTextField from '@/components/BaseTextField.vue';
import BaseAutocompleteField from '@/components/BaseAutocompleteField.vue';

const _ = require('lodash');

export default {
  name: 'Simulações',
  components: {
    TheToolbar, BaseCardDetailsSimulacao, BaseTextField, BaseAutocompleteField,
  },
  data() {
    return {
      loading: false,
      list: [],
      dialogFiltro: false,
      filtroAtivo: false,
      filtros: {
        orderBy: 'data',
        nome: '',
        dataInicio: null,
        dataFim: null,
      },
      orderBy: [{
        titulo: 'Por Data',
        valor: 'data',
      },
      {
        titulo: 'Por nome',
        valor: 'nome',
      },
      ],
    };
  },
  created() {
    this.getSimulacoes();
  },
  methods: {
    ...mapActions({
      setTitular: 'simulacao/setTitular',
      setPlano: 'simulacao/setPlano',
      setAnotacao: 'simulacao/setAnotacao',
      resetDados: 'simulacao/resetDados',
    }),
    async filtrar() {
      this.filtroAtivo = true;
      this.dialogFiltro = false;
      this.getSimulacoes();
    },
    limparFiltros() {
      this.filtroAtivo = false;
      this.dialogFiltro = false;
      this.filtros = {
        orderBy: 'data',
        nome: '',
        dataInicio: null,
        dataFim: null,
      };
      this.getSimulacoes();
    },
    async getSimulacoes() {
      this.loading = true;
      try {
        this.list = await simulacaoService.getSimulacoes({ filtros: this.filtros });
        this.list = this.groupByDadosTitular(this.list);
        this.loading = false;
      } catch (error) {
        this.$root.$snackBar.open({
          color: 'error',
          message: error.message,
        });
        this.loading = false;
      }
    },
    groupByDadosTitular(dados) {
      const result = [];
      const index = 0;
      while (dados.length) {
        const { titular } = dados[index];
        const simulacaoTitular = dados.filter((e) => e.titular.nome === titular.nome
            && e.titular.email === titular.email
            && e.titular.telefone[0].numero === titular.telefone[0].numero);
        result.push({
          nome: titular.nome,
          email: titular.email,
          telefone: titular.telefone[0].numero,
          tipoTelefone: titular.telefone[0].tipo,
          simulacoes: simulacaoTitular,
        });
        dados = this.removeByItem(dados, {
          nome: titular.nome,
          email: titular.email,
          telefone: titular.telefone,
        });
      }
      return result;
    },
    removeByItem(dados, { nome, email, telefone }) {
      let dadoParaRemover = dados.findIndex((e) => e.titular.nome === nome
            && e.titular.email === email
            && e.titular.telefone[0].numero === telefone[0].numero);
      while (dadoParaRemover !== -1) {
        dados.splice(dadoParaRemover, 1);
        dadoParaRemover = dados.findIndex((e) => e.titular.nome === nome
            && e.titular.email === email
            && e.titular.telefone[0].numero === telefone[0].numero);
      }
      return dados;
    },
    editar(item) {
      this.$router.push({ name: 'areaLogada.simulacaoCliente', params: { item } });
    },
    simulacaoPagina() {
      this.$router.push({ name: 'areaLogada.criarSimulacao' });
    },
    criarSimulacao() {
      this.resetDados();
      this.simulacaoPagina();
    },
  },
};
</script>

<style>

</style>
