var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("base-card-details", {
    attrs: { title: _vm.nome || "Não definido" },
    scopedSlots: _vm._u([
      {
        key: "header",
        fn: function () {
          return [
            _c(
              "v-list-item-avatar",
              { attrs: { dark: "", color: "grey", size: "34" } },
              [
                _c("span", { staticClass: "white--text subtitle-1 mt-1" }, [
                  _vm._v(_vm._s(_vm._f("avatarName")(_vm.nome))),
                ]),
              ]
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "body",
        fn: function () {
          return [
            _c(
              "v-row",
              { attrs: { "no-gutters": "" } },
              [
                _c("v-col", { staticClass: "mb-4", attrs: { cols: "12" } }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Email"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.email))]),
                  ]),
                ]),
                _c("div", { staticClass: "d-flex mb-4 col-12" }, [
                  _c("div", [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Telefone"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.telefone))]),
                  ]),
                  _c("div", { staticClass: "ml-6" }, [
                    _c("h3", { staticClass: "mb-1 subtitle-2" }, [
                      _vm._v("Tipo Telefone"),
                    ]),
                    _c("span", [_vm._v(_vm._s(_vm.tipoTelefone))]),
                  ]),
                ]),
              ],
              1
            ),
          ]
        },
        proxy: true,
      },
      {
        key: "footer",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex justify-space-between col-12" }, [
              _vm.quantidade === 1
                ? _c("div", { staticClass: "text-caption" }, [
                    _vm._v(_vm._s(_vm.quantidade) + " Simulação vinculada"),
                  ])
                : _c("div", { staticClass: "text-caption" }, [
                    _vm._v(_vm._s(_vm.quantidade) + " Simulações vinculadas"),
                  ]),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }